import AppLoadingModal from 'src/components/common/app-loading-modal';
import PropTypes from 'prop-types';
import React from 'react';
import { inject, observer } from 'mobx-react';

const AppDefaultModal = ({ ui }) => {
  const modalVisible = Boolean(ui.modalStates.title || ui.modalStates.noTitle);
  return (
    <AppLoadingModal
      key={`${ui.modalStates.key}-${modalVisible.toString()}`}
      title={ui.modalStates.title}
      visible={modalVisible}
      okText={ui.modalStates.okText || 'Confirm'}
      cancelText={ui.modalStates.cancelText || 'Cancel'}
      onCancel={ui.modalStates.onCancel}
      onOk={ui.modalStates.onOk}
      elementToFocus={ui.modalStates.elementToFocus}
      {...ui.modalStates}
    >
      {ui.modalStates.children ? ui.modalStates.children : null}
    </AppLoadingModal>
  );
};

AppDefaultModal.propTypes = {
  ui: PropTypes.object.isRequired,
};

export default inject('ui')(observer(AppDefaultModal));
