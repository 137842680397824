

import React, { Component } from 'react';
import { DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ActionsMenu from 'src/components/common/actions-menu';
import { LoadingAnchorButton } from 'src/components/common/anchor-button';
import AppButton from 'src/components/common/app-button';
import PromiseButton from 'src/components/common/promise-button';

const clsPrefix = 'app-task-action';

const TYPES = ['default', 'primary', 'danger'];

const getButtonType = ({ type, layout }) => {
  return type || (layout === 'horizontal-buttons' ? 'default' : 'primary');
};

class TaskActionSubMenu extends Component {
  static propTypes = {
    children: PropTypes.any,
    handleClick: PropTypes.func,
    layout: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    size: PropTypes.string,
  };

  static defaultProps = {
    size: 'default',
  };

  renderChildren = () => {
    const { children, handleClick } = this.props;
    return isFunction(children) ? children(handleClick) : children;
  };

  render() {
    const { layout, children, type, size, ...props } = this.props;
    if (layout === 'menu') {
      return (
        <ActionsMenu.SubMenu
          {...omit(props, [
            'above',
            'inline',
            'allTasks',
            'openTask',
            'taskMode',
            'handleClick',
            'showUpgradeModal',
          ])}
        >
          {this.renderChildren()}
        </ActionsMenu.SubMenu>
      );
    }

    let ButtonComponent;
    if (layout === 'horizontal-buttons') {
      ButtonComponent = AppButton;
    } else if (layout === 'default') {
      ButtonComponent = LoadingAnchorButton;
    }
    return (
      <ActionsMenu
        disabled={props.disabled}
        size={size}
        button={
          <ButtonComponent
            type={getButtonType({
              type,
              layout,
            })}
          >
            {props.title} <DownOutlined />
          </ButtonComponent>
        }
      >
        {children}
      </ActionsMenu>
    );
  }
}

@observer
export default class TaskAction extends Component {
  static SubMenu = TaskActionSubMenu;

  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(TYPES),
    onClick: PropTypes.func,
    /* When used as a child of a submenu, onClick will be overridden by
    the parent. onClick_ can be used in this case */
    onClick_: PropTypes.func,
    children: PropTypes.any,
    handleClick: PropTypes.func,
    layout: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    ghost: PropTypes.bool,
    href: PropTypes.string,
  };

  static defaultProps = {
    handleClick: (...handlers) => async (e) => {
      if (!e) {
        return;
      }

      if (e.domEvent) {
        e.domEvent.stopPropagation();
      }

      if (e.stopPropagation) {
        e.stopPropagation();
      }

      for (let i = 0; i < handlers.length; i++) {
        if (handlers[i]) {
          // eslint-disable-next-line no-await-in-loop
          await handlers[i](e ? e.key : undefined);
        }
      }
    },
  };

  renderMenu() {
    const {
      className,
      onClick,
      type,
      handleClick,
      onClick_,
      disabled,
      loading,
      ...props
    } = this.props;
    const handler = onClick_ ? handleClick(onClick_) : handleClick(onClick);

    return (
      <ActionsMenu.ButtonItem
        {...props}
        disabled={disabled}
        loading={loading}
        onClick_={handler}
        className={classNames(
          className,
          clsPrefix,
          `${clsPrefix}--${type || 'default'}`,
          'ant-dropdown-menu-item'
        )}
      />
    );
  }

  renderLink() {
    const {
      className,
      onClick,
      type,
      children,
      layout,
      onClick_,
      handleClick,
      disabled,
      loading,
      ghost,
      href,
    } = this.props;

    const handler = onClick_ ? handleClick(onClick_) : handleClick(onClick);

    let ButtonComponent;
    if (layout === 'horizontal-buttons') {
      ButtonComponent = PromiseButton;
    } else if (layout === 'default') {
      ButtonComponent = LoadingAnchorButton;
    }
    return (
      <ButtonComponent
        disabled={disabled}
        loading={loading}
        className={classNames(className, clsPrefix)}
        type={getButtonType({
          type,
          layout,
        })}
        href={href}
        onClick={handler}
        ghost={ghost}
      >
        {children}
      </ButtonComponent>
    );
  }

  render() {
    const { layout } = this.props;
    return layout === 'menu' ? this.renderMenu() : this.renderLink();
  }
}
