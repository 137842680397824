type SyncFunction = (...args: unknown[]) => unknown;

export default function chaining(...fns: SyncFunction[]) {
  return function handleChaining(this: unknown, ...args: unknown[]) {
    for (let i = 0; i < fns.length; i++) {
      if (fns[i] && typeof fns[i] === 'function') {
        (fns[i] as SyncFunction).apply(this, args);
      }
    }
  };
}

export function chainingAsync(...fns: SyncFunction[]) {
  return async function handleChaining(this: unknown, ...args: unknown[]) {
    for (let i = 0; i < fns.length; i++) {
      if (fns[i] && typeof fns[i] === 'function') {
        await (fns[i] as SyncFunction).apply(this, args); // eslint-disable-line no-await-in-loop
      }
    }
  };
}

type AsyncFunctionWithObject = (
  ...args: unknown[]
) => Promise<Record<string, any>>;

export const chainObjectSeries =
  (...fns: AsyncFunctionWithObject[]) =>
  async (...args: unknown[]) => {
    let res = {};

    for (let i = 0; i < fns.length; i++) {
      res = {
        ...res,
        ...((await fns[i](...args)) || {}), // eslint-disable-line no-await-in-loop
      };
    }

    return res;
  };

export const chainObjectParallel =
  (...fns: AsyncFunctionWithObject[]) =>
  async (...args: unknown[]) => {
    const rawRes = await Promise.all(fns.map((fn) => fn(...args)));
    return rawRes.reduce(
      (res, fnRes) => ({
        ...res,
        ...(fnRes || {}),
      }),
      {}
    );
  };

type AsyncFunctionWithUnknown = (...args: unknown[]) => Promise<unknown>;
// Executes all provided async functions in parallel, and returns results of all
//  chained function, without being reduced.
export const chainReturnAll =
  (...fns: AsyncFunctionWithUnknown[]) =>
  (...args: unknown[]) =>
    Promise.all(fns.map((fn) => fn(...args)));

// Executes all provided async functions in parallel, and returns only the result of the
//  last chained function.
export const chainReturnLast =
  (...fns: AsyncFunctionWithUnknown[]) =>
  async (...args: unknown[]) => {
    const rawRes = await Promise.all(fns.map((fn) => fn(...args)));
    return rawRes.slice(-1)[0];
  };
