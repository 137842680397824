import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import get from 'lodash/get';
@inject('router', 'ui')
@observer
export default class Link extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  static propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    routeName: PropTypes.string,
    routeParams: PropTypes.object,
    routeOptions: PropTypes.object,
    ui: PropTypes.object,
    activeClassName: PropTypes.string,
    activeStrict: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    style: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
    ]),
    router: PropTypes.object.isRequired,
    autoBack: PropTypes.bool,
    openInNewTab: PropTypes.bool,
    stopPropagation: PropTypes.bool,
    fullUrl: PropTypes.string,
  };

  static defaultProps = {
    activeClassName: 'active',
    activeStrict: false,
    routeParams: {},
    routeOptions: {},
  };

  constructor(props, context) {
    super(props, context);

    this.isActive = this.isActive.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
  }

  getRouter() {
    return this.props.router.router;
  }

  get routeParams() {
    const { router, routeParams, autoBack } = this.props;
    const route = router.hotRoute;
    const defaultBackRoute = route
      ? router.buildPath(route.name, route.params)
      : undefined;

    return {
      ...(routeParams || {}),
      back:
        get(routeParams, 'back') || (autoBack ? defaultBackRoute : undefined),
    };
  }

  buildUrl(routeName, routeParams) {
    if (!routeName) {
      return '';
    }

    if (this.getRouter().buildUrl) {
      return this._safeBuildRoute(
        this.getRouter().buildUrl,
        routeName,
        routeParams
      );
    }

    return this._safeBuildRoute(
      this.getRouter().buildPath,
      routeName,
      routeParams
    );
  }

  // There is some routeName which cross in entries, the routeName maybe not under the specific entry.
  // Build not exist routeName only return null in router5 v7, but it will throw errir in router5 v8;
  _safeBuildRoute(buildMethod, routeName, routeParams) {
    try {
      return buildMethod(routeName, routeParams);
    } catch (error) {
      window.DD_RUM?.addError(error, {
        extra: {
          url: window.location.href,
          routeName, // the error which router5 throw will not include routeName
          routeParams,
        },
      });
      return null;
    }
  }

  isActive() {
    return this.getRouter().isActive(
      this.props.routeName,
      this.routeParams,
      this.props.activeStrict
    );
  }

  clickHandler(evt) {
    if (this.props.openInNewTab) {
      return;
    }

    if (!this.props.routeName) {
      if (evt.preventDefault) {
        evt.preventDefault();
      }
      return;
    }

    if (this.props.stopPropagation && evt.stopPropagation) {
      evt.stopPropagation();
    }
    if (this.props.onClick) {
      this.props.onClick(evt);

      if (evt.defaultPrevented) {
        return;
      }
    }

    // if app is embedded, alt combo click allows the link to "download"
    // so keeping the functionality here, unless change is nessessary
    const comboKey =
      evt.altKey ||
      (!this.props.ui.isEmbedded &&
        (evt.metaKey || evt.ctrlKey || evt.shiftKey));

    if (evt.button === 0 && !comboKey) {
      if (evt.preventDefault) {
        evt.preventDefault();
      }
      // Need to use router-store.navigate to trigger side effects.
      this.props.router.navigate(
        this.props.routeName,
        this.routeParams,
        this.props.routeOptions
      );
    }
  }

  render() {
    this.props.router.hotRoute; // eslint-disable-line
    // This will re-render() this component via observable.

    const {
      routeName,
      ui,
      className,
      activeClassName,
      children,
      title,
      onMouseOver,
      onMouseEnter,
      onMouseLeave,
      style,
      openInNewTab,
      fullUrl,
      ariaLabel,
    } = this.props;

    const active = this.isActive();
    const href = fullUrl || this.buildUrl(routeName, this.routeParams);
    const linkclassName = (className ? className.split(' ') : [])
      .concat(active ? [activeClassName] : [])
      .join(' ');

    return React.createElement(
      'a',
      {
        href: ui.isEmbedded ? undefined : href,
        className: linkclassName,
        onClick: this.clickHandler,
        onMouseOver,
        onMouseEnter,
        onMouseLeave,
        title,
        style,
        target: openInNewTab ? '_blank' : null,
        'aria-label': ariaLabel,
      },
      children
    );
  }
}
