import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RouterProvider from './router/router-provider';
import enUS from 'antd/lib/locale-provider/en_US';
import { ConfigProvider } from 'antd';
import { Provider } from 'mobx-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
  receiveMessage as receiveMobileBridgeMessage,
  disposeListener as disposeMobileBridgeListener,
} from 'src/utils/mobile-bridge';
import {
  sendActionsMenuListener,
  triggerActionListener,
} from 'src/models/transactions/actions-menu/actions-menu-listeners';

export default class AppProvider extends Component {
  // eslint-disable-line
  static propTypes = {
    router: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      stripe: window.Glide.STRIPE_KEY
        ? loadStripe(window.Glide.STRIPE_KEY)
        : null,
    };
  }

  componentDidMount() {
    if (this.state.stripe) {
      document.querySelector('#stripe-js')?.addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({
          stripe: loadStripe(window.Glide.STRIPE_KEY),
        });
      });
    }

    receiveMobileBridgeMessage({
      name: 'txn-actions-menu-request',
      cb: sendActionsMenuListener,
      permanent: true,
    });

    receiveMobileBridgeMessage({
      name: 'txn-action-trigger',
      cb: triggerActionListener,
      permanent: true,
    });
  }

  componentWillUnmount() {
    disposeMobileBridgeListener([
      'txn-actions-menu-request',
      'txn-action-trigger',
    ]);
  }

  render() {
    return (
      <Provider
        store={this.props.store}
        router={this.props.store.router}
        ui={this.props.store.ui}
        transactions={this.props.store.transactions}
        transactionTemplates={this.props.store.transactionTemplates}
        transactionUsers={this.props.store.transactionUsers}
        transactionDocuments={this.props.store.transactionDocuments}
        transactionFields={this.props.store.transactionFields}
        transactionForms={this.props.store.transactionForms}
        tasks={this.props.store.tasks}
        checklists={this.props.store.checklists}
        templates={this.props.store.templates}
        sharedPackets={this.props.store.sharedPackets}
        signaturePackets={this.props.store.signaturePackets}
        packets={this.props.store.packets}
        account={this.props.store.account}
        integrations={this.props.store.integrations}
        addresses={this.props.store.addresses}
        contacts={this.props.store.contacts}
        documents={this.props.store.documents}
        forms={this.props.store.forms}
        audit={this.props.store.audit}
        features={this.props.store.features}
        orgs={this.props.store.orgs}
        activities={this.props.store.activities}
        flows={this.props.store.flows}
        uploads={this.props.store.uploads}
        transactionsList={this.props.store.transactionsList}
        zipformTransactions={this.props.store.zipformTransactions}
        zipformUpload={this.props.store.zipformUpload}
        annotations={this.props.store.annotations}
        templateChecklists={this.props.store.templateChecklists}
        fillConfigs={this.props.store.fillConfigs}
        comms={this.props.store.comms}
        clauses={this.props.store.clauses}
        pdfEsign={this.props.store.pdfEsign}
        envelopes={this.props.store.envelopes}
        packages={this.props.store.packages}
        embeddedApp={this.props.store.embeddedApp}
        formBuilder={this.props.store.formBuilder}
        missingSignatures={this.props.store.missingSignatures}
      >
        <Elements stripe={this.state.stripe}>
          <RouterProvider router={this.props.router}>
            <ConfigProvider locale={enUS}>{this.props.children}</ConfigProvider>
          </RouterProvider>
        </Elements>
      </Provider>
    );
  }
}
