

import React from 'react';
import classNames from 'classnames';
import AppButton, {
  AppButtonProps,
  AppButtonSize,
  AppButtonType,
} from './app-button';
import AppIcon from './app-icon';

const clsPrefix = 'app-icon-button';
const LoadingIcon = (...props) => (
  <AppIcon {...props} importSvg={undefined} type="antd" name="loading" />
);

export interface AppIconButtonProps extends AppButtonProps {
  buttonSize?: AppButtonSize;
  buttonType?: AppButtonType;
  // TODO: When AppIcon is migrated to TS, the type should referenced from there
  iconType?: 'antd' | 'svg' | 'feather';
  importSvg?: () => any;
  svg?: any;
  iconSize?: number;
  alt?: string;
  iconProps?: Record<string, any>;
  label?: string | React.ReactNode;
  labelPlacement?: 'bottom' | 'top';
}

const AppIconButton = ({
  className,
  buttonSize,
  buttonType,
  ghost,
  shape,
  borderless,
  iconType,
  name,
  importSvg,
  svg,
  iconSize,
  alt,
  iconProps,
  loading,
  label,
  labelPlacement,
  ...buttonProps
}: AppIconButtonProps) => {
  const IconComp = loading ? LoadingIcon : AppIcon;

  return (
    <AppButton
      {...{
        className: `${clsPrefix} ${className || ''}`.trim(),
        size: buttonSize,
        type: buttonType,
        ghost,
        shape,
        borderless,
      }}
      {...buttonProps}
    >
      {!!label && labelPlacement === 'top' && (
        <span
          className={classNames(
            `${clsPrefix}__label`,
            `${clsPrefix}__label--top`
          )}
        >
          {label}
        </span>
      )}
      <IconComp
        {...{
          type: iconType,
          name,
          importSvg,
          svg,
          size: iconSize,
          alt,
        }}
        {...iconProps}
      />
      {!!label && labelPlacement === 'bottom' && (
        <span
          className={classNames(
            `${clsPrefix}__label`,
            `${clsPrefix}__label--bottom`
          )}
        >
          {label}
        </span>
      )}
    </AppButton>
  );
};

AppIconButton.defaultProps = {
  labelPlacement: 'bottom',
};

export default AppIconButton;
