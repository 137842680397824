import appStore from 'src/stores/app-store';
import omit from 'lodash/omit';
import {
  OPERATION_CLONE_TRANSACTION,
  type Transaction,
} from 'src/models/transactions/transaction';
import { sendMessage as sendMobileBridgeMessage } from 'src/utils/mobile-bridge';
import { transactionActionsMenu } from './actions-menu';
import { triggerTransactionAction } from './actions-menu-handlers';

export async function sendActionsMenuListener(transactionId: string) {
  const transaction = (await appStore.transactions.getOrFetchTransaction(
    transactionId
  )) as Transaction;
  const transactionActions = transactionActionsMenu(transaction);

  const filteredActions = omit(transactionActions, [
    OPERATION_CLONE_TRANSACTION,
  ]);

  sendMobileBridgeMessage({
    data: {
      event: `txn-actions-menu-response`,
      options: filteredActions,
    },
  });

  return filteredActions;
}

export async function triggerActionListener(
  transactionId: string,
  actionKey: string,
  ...args: unknown[]
) {
  const transaction = (await appStore.transactions.getOrFetchTransaction(
    transactionId
  )) as Transaction;
  await triggerTransactionAction(transaction, actionKey, undefined, ...args);
}
