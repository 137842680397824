

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Spin } from 'antd';

const clsPrefix = 'app-full-spinner';

export default function FullSpinner({ style, showOverTopBar }) {
  return (
    <div
      className={classNames(clsPrefix, {
        [`${clsPrefix}--over-top-bar`]: !!showOverTopBar,
      })}
      style={style}
    >
      <Spin size="large" />
    </div>
  );
}

FullSpinner.propTypes = {
  style: PropTypes.any,
  showOverTopBar: PropTypes.bool,
};
