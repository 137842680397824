import type { NavigationOptions, Params } from 'router5/dist/types/base';
import type RouterStore from '../stores/router-store';

function preventDefaultAndGetComboKey(evt: KeyboardEvent) {
  const comboKey =
    evt && (evt.metaKey || evt.altKey || evt.ctrlKey || evt.shiftKey);
  if (evt && evt.preventDefault) {
    evt.preventDefault();
  }
  return comboKey;
}

export function navigateClickPath(evt: KeyboardEvent, path: string) {
  const comboKey = preventDefaultAndGetComboKey(evt);
  if (comboKey) {
    window.open(path as string);
    return true;
  }

  window.location.href = path;
  return true;
}

export default function navigateClick(
  evt: KeyboardEvent,
  router: RouterStore,
  name: string,
  params: Params = {},
  options: NavigationOptions = {}
) {
  // if Glide is embedded, remove all combo key interactions
  //    alt + click is supposed to download, but it opens new tab instead
  //    when embedded
  const preventComboKeys = router.parent?.ui.isEmbedded;
  const comboKey = preventDefaultAndGetComboKey(evt);

  if (comboKey && !preventComboKeys) {
    const path = router.buildUrl(name, params);
    window.open(path);
    return true;
  }
  return router.navigate(name, params, options);
}

export function navigateClickAllowPaths(
  evt: KeyboardEvent,
  router: RouterStore,
  name: string,
  params?: Params
) {
  if (name.startsWith('/')) {
    return navigateClickPath(evt, name);
  }
  return navigateClick(evt, router, name, params);
}
